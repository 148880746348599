import { SMALL_MAX_PX } from '../../../../constants/breakpoints';
import { CloudinaryOptions } from '../../../../helpers/cloudinary-helper/cloudinary-helper';
import { MediaGroupLayout } from '../media-group/media-group.handler';

export type MediaCardOptions = {
	className: string;
	optionsTall: CloudinaryOptions;
	optionsWide: CloudinaryOptions;
	cols?: number;
	mediaClassName: string;
};

type OptionMap = { [K in MediaGroupLayout]: MediaCardOptions };

export const mediaCardsOptions: OptionMap = {
	hero: {
		cols: 1,
		className: 'w-100 mt3',
		optionsTall: { width: 1024, crop: 'lfill' },
		optionsWide: { height: 1024, crop: 'lfill' },
		mediaClassName: 'flex flex-column items-center w-100'
	},
	'row-of-2': {
		cols: 2,
		className: 'w-100 w-50-ns pa3',
		optionsTall: { width: 512, crop: 'fill' },
		optionsWide: { height: 300, crop: 'lfill' },
		mediaClassName: 'w-100'
	},
	'row-of-3': {
		cols: 3,
		className: 'w-100 w-33-ns pa3',
		optionsTall: { width: SMALL_MAX_PX, crop: 'pad' },
		optionsWide: { height: 150, crop: 'lfill' },
		mediaClassName: 'w-100'
	},
	'row-of-4': {
		cols: 4,
		className: 'w-100 w-25-ns pa3',
		optionsTall: { width: SMALL_MAX_PX, crop: 'pad' },
		optionsWide: { height: 150, crop: 'lfill' },
		mediaClassName: 'w-100'
	},
	'row-of-5': {
		cols: 5,
		className: 'w-100 w-20-ns pa3',
		optionsTall: { width: SMALL_MAX_PX, crop: 'pad' },
		optionsWide: { height: 150, crop: 'lfill' },
		mediaClassName: 'w-100'
	},
	'row-of-maximum': {
		className: 'w-20',
		optionsTall: { width: SMALL_MAX_PX },
		optionsWide: { height: 150, crop: 'lfill' },
		mediaClassName: 'w-100'
	}
};

type OptionOverrideMap = {
	[key: string]: { [K in MediaGroupLayout]?: Partial<MediaCardOptions> };
};
// schema based overrides to the style map above
const mediaCardOptionOverrides: OptionOverrideMap = {
	'support-page@1': {
		'row-of-2': {
			mediaClassName: 'w-20 center'
		}
	}
};

export const mediaCardsInfo = (schemaType: string | undefined, layout = 'hero'): MediaCardOptions => {
	let layoutOptions = mediaCardsOptions[layout];
	if (schemaType) {
		const override = mediaCardOptionOverrides[schemaType]?.[layout] || {};
		layoutOptions = {
			...layoutOptions,
			...override
		};
	}

	return layoutOptions;
};
