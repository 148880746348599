import React from 'react';
import { ContentComponent, CallToActionContent } from '../../../../types/construct.types';
import { LinkButton } from '../../../buttons/link-button/link-button.component';
import { addContentHandler } from '../add-content-handler';

export const CallToAction: ContentComponent<CallToActionContent> = ({ content }) => (
	<div>
		<LinkButton url={content.fields.cta_url.linkUrl}>{content.fields.cta}</LinkButton>
	</div>
);

addContentHandler('content-common', 'ObjectContent', 'cta', CallToAction);
