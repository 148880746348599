import React from 'react';
import { ContentComponent, ContentSectionFullContent } from '../../../../types/construct.types';
import { LinkButton } from '../../../buttons/link-button/link-button.component';
import { BackgroundImage } from '../../../images/background-image/background-image.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { addContentHandler } from '../add-content-handler';
import { RichText } from '../rich-text/rich-text.handler';
import { backgroundImageGradientFull } from './content-section-full.css';

export const ContentSectionFull: ContentComponent<ContentSectionFullContent> = ({ content, options, renderer }) => {
	const { heading, body_copy, media_image, media_image_overlay, media_video, cta, cta_url } = content.fields;

	const richText = body_copy?.richText;
	const link = cta_url?.linkUrl;
	const contentVideo = media_video?.items[0];
	const overlayImage = media_image_overlay?.image;

	return (
		<BackgroundImage
			publicID={media_image.image?.id}
			options={{
				width: 1700,
				height: 444,
				crop: 'lfill',
				gravity: 'custom',
				format: 'auto'
			}}
			className={`bg-right cover relative flex ${backgroundImageGradientFull}`}>
			<div className="center-ns mw9-ns pa3 pa5-m pt0-ns z-1 w-100">
				<div className="theme-white flex-ns flex-row-ns justify-between-ns items-start">
					<div className="w-60-ns mv4 mt0-ns flex-ns flex-column-ns">
						<div>{heading && <h1 className="mb0 b--theme-white bb">{heading}</h1>}</div>
						<div>{richText && <RichText className="lh-copy" rawHtml={richText} />}</div>
						{link && <LinkButton url={link}>{cta}</LinkButton>}
						<div className="relative mt5 w3 nl3">
							{contentVideo && renderer.render(media_video, 0, { ...options, ContentSection: true })}
						</div>
					</div>
					<div className="flex justify-end items-end mv4">
						{overlayImage?.id && (
							<CloudinaryImage
								className={'mv4-ns mb4'}
								publicID={overlayImage.id}
								description={overlayImage.description}
								options={{ height: 160, width: 200 }}
							/>
						)}
					</div>
				</div>
			</div>
		</BackgroundImage>
	);
};

addContentHandler('content-section-full', 'ObjectContent', 'content-section-full@1', ContentSectionFull);
